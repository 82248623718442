import type { NotificationServiceInterface } from './interface';

export class NotificationService
implements NotificationServiceInterface {
  notice(message, timeout?: number) {
    // eslint-disable-next-line
    //@ts-ignore
    window.notice(message, timeout);
  }

  error(message, timeout = undefined, callback = undefined, hideCloseButton = false) {
    // eslint-disable-next-line
      //@ts-ignore
    window.error(message, timeout, callback, hideCloseButton);
  }
}
