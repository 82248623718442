import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import deTranslation from '@/translations/de_DE.json';
import enDubaiTranslation from '@/translations/en_DUBAI.json';
import enTranslation from '@/translations/en_EN.json';
import esTranslation from '@/translations/es_ES.json';
import frTranslation from '@/translations/fr_FR.json';
import plTranslation from '@/translations/pl_PL.json';
import ptTranslation from '@/translations/pt_BR.json';
import { LANGUAGE } from '@/types/types';
import { config } from '@/config';

const translations = {
  [LANGUAGE.EN]: { translation: enTranslation },
  [LANGUAGE.EN_DUBAI]: { translation: enDubaiTranslation },
  [LANGUAGE.PL]: { translation: plTranslation },
  [LANGUAGE.DE]: { translation: deTranslation },
  [LANGUAGE.PT]: { translation: ptTranslation },
  [LANGUAGE.ES]: { translation: esTranslation },
  [LANGUAGE.FR]: { translation: frTranslation },
};

// TODO: Enable after the TypeScript errors have been fixed
// declare module 'i18next' {
//   interface CustomTypeOptions {
//     resources: {
//       translation: typeof enTranslation
//     }
//   }
// }

i18n
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: LANGUAGE.EN,
    lng: config.language,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnEmptyString: false,
  });

export default i18n;
